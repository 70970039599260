import React from "react";
import MKBox from "components/MKBox";
import footerRoutes from "footer.routes";
import FooterContent from "./sections/footerContent";

const Footer = () => {
  return (
    <>
      <MKBox pt={6} px={1} mt={6}>
        <FooterContent content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Footer;
