import React from "react";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Email from "@mui/icons-material/Email";
import LocationOn from "@mui/icons-material/LocationOn";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import PropTypes from "prop-types";

function Contact({ brand }) {
  const socials = {
    links: [
      {
        icon: <FacebookIcon />,
        link: "https://www.facebook.com/CreativeTim/",
      },
      {
        icon: <TwitterIcon />,
        link: "https://twitter.com/creativetim",
      },
      {
        icon: <YouTubeIcon />,
        link: "https://www.youtube.com/channel/UCVyTG4sCw-rOvB9oHkzZD1w",
      },
    ],
  };

  return (
    <>
      <Grid item xs={12} md={4} sx={{ ml: 3, mb: 3 }}>
            <MKBox>
              {/* <Link to={brand.route}>
                <MKBox component="img" src={brand.image} alt={brand.name} maxWidth="2rem" mb={2} />
              </Link> */}
              <MKTypography
                display="block"
                variant="button"
                fontWeight="bold"
                textTransform="capitalize"
                color="error"
                mb={1}
              >
                Contact Us
              </MKTypography>

              <MKTypography variant="h6" color="error">
                <LocationOn color="error"/> {brand.address}
              </MKTypography>

              <MKTypography variant="h6" color="error">
                <Email color="error" />  {brand.email}
              </MKTypography>

              <MKTypography variant="h6" color="error">
                <PhoneInTalkIcon color="error"/> {brand.tel}
              </MKTypography>

              <MKTypography variant="h6" color="error">{brand.contactUs}</MKTypography>
            </MKBox>
            <MKBox display="flex" alignItems="center" mt={2}>
              {socials.links.map(({ icon, link }, key) => (
                <MKTypography
                  key={link}
                  component="a"
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  variant="h5"
                  color="error"
                  opacity={0.8}
                  mr={key === socials.length - 1 ? 0 : 2.5}
                >
                  {icon}
                </MKTypography>
              ))}
            </MKBox>
          </Grid>
    </>
  );
}

Contact.propTypes = {
   brand: PropTypes.object
}

export default Contact;