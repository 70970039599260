import MKTypography from "components/MKTypography";
import logo from "assets/images/cristlesteak/castlesteak-logo.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Castle Steak House",
    image: logo,
    route: "/",
    address: "Canada",
    email: "castlesteakhouse@gmail.com",
    tel: "+672138217392",
    contactUs: "",
  },
  copyright: (
    <MKTypography variant="button" fontWeight="regular" color="light">
      All rights reserved. Copyright &copy; {date} Castle Stake House Developed by{" "}
      <MKTypography
        component="a"
        href="http://test.cisworld.lk/"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
        color="light"
      >
        CIS WORLD
      </MKTypography>
      .
    </MKTypography>
  ),
};
