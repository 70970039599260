// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import CopyRight from "./CopyRight";
import Contact from "./Contact";
import UsefullLinks from "./UsefulLinks";
import AppDownload from "./AppDownload";

const FooterContent = ({ content }) => {
  // const { brand, copyright } = content;

  return (
    <MKBox component="footer" >
      <Container>
        <Grid container spacing={3}>

          <Contact brand={content.brand} />
          
          <UsefullLinks />

          <AppDownload />
            
          <CopyRight copyright={content.copyright} />
        </Grid>
      </Container>
    </MKBox>
  );
}

FooterContent.propTypes = {
  content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};

export default FooterContent;
