import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import {
  fetchRestuarantsByMenuItem,
  selectRestuarant,
} from "../../../store/slices/MenuItemSlice";


const SelectorOption = () => {
  const dispatch = useDispatch();
  const { restuarant, restuarantsByMenuItem } = useSelector(
    (state) => state.menuItem
  );
  const handleRestuarantChange = (event) => {
    dispatch(selectRestuarant(event.target.value));
  };

  const selectStyles = {
    padding: "1px",
    margin: "2px",
    background: "#fff",
    borderColor: "##344767",
    color: "##344767",
    fontSize: "1rem",
  };

  return (
    <MKBox component="section" mt={5}>
      <Container>
        <Grid item xs={12} lg={6} textAlign="center">
          <Grid container spacing={3}>
            <MKTypography variant="h5" mb={1} mr={2}>
              Select Restuarant :{" "}
            </MKTypography>
            <select
              value={restuarant}
              onChange={handleRestuarantChange}
              style={selectStyles}
            >
              <option value="0" key={"all"}>
                All
              </option>
              {restuarantsByMenuItem.map((option) => (
                <option value={option.id} key={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
};


export default SelectorOption;