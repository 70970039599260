import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../api"

const initialState = {
  restuarants: [],
  restuarant: 0,
  isLoading: false,
  error: false,
};

const ResturatSlice = createSlice({
  name: "restuarant",
  initialState,
  reducers: {
    startLoading: state => {
      state.isLoading = true;
   },
   hasError: (state, action) => {
     state.error = action.payload;
      state.isLoading = false;
    },
    restuarantsSuccess: (state, action) => {
      state.restuarants = action.payload;
      state.isLoading = false;
    },
    selectRestuarant: (state, action) => {
      state.restuarant = action.payload;
    }
  },
});

export const { startLoading, hasError, restuarantsSuccess, selectRestuarant } =
  ResturatSlice.actions;
export default ResturatSlice.reducer;

export const fetchRestuarants = () => async (dispatch) => {
  dispatch(startLoading());
  try {
    await api
      .get("/get_branches")
      .then((response) => {
        dispatch(restuarantsSuccess(response.data.data))
      });
  } catch (e) {
    dispatch(hasError(e.message));
    return console.error(e.message);
    
  }
};
