import { createSlice } from "@reduxjs/toolkit";
import React, { useEffect } from "react";
import api from "../api";

const initialState = {
  bannerItems: [],
  isLoading: false,
  error: false,
  categoryTime : [
    {
      'name' : "BREAKFAST",
      'time' : "6:30AM-12:00PM"
    },
    {
      'name' : "LUNCH",
      'time' : "6:30AM-12:00PM"
    },
    {
      'name' : "Dinner",
      'time' : "6:30AM-12:00PM"
    },
    {
      'name' : "drinkes",
      'time' : "6:30AM-12:00PM"
    },
  ]
};

const banner = [
    {
        'image' : 'hero.jpg',
        'lable' : 'Fast and Quality Foods',
        'title' : 'Work with the rockets',
        'description' : 'Wealth creation is an evolutionarily recent positive-sum game. Status is an old zero-sum game. Those attacking wealth creation are often just seeking status.',
    },
    {
        'image' : 'hero.jpg',
        'lable' : 'Fast and Quality Foods',
        'title' : 'Work with the rockets',
        'description' : 'Wealth creation is an evolutionarily recent positive-sum game. Status is an old zero-sum game. Those attacking wealth creation are often just seeking status.',
    },
    {
        'image' : 'hero.jpg',
        'lable' : 'Fast and Quality Foods',
        'title' : 'Work with the rockets',
        'description' : 'Wealth creation is an evolutionarily recent positive-sum game. Status is an old zero-sum game. Those attacking wealth creation are often just seeking status.',
    },
];

export const fetchBanner = () => async (dispatch) => {
    dispatch(bannerSuccess(banner))
//   dispatch(startLoading());
//   try {
//     await api
//       .get(`/get_menu-items?${restuarant_id}${category_id}`)
//       .then((response) => {
//         dispatch(bannerSuccess(response.data.data));
//       });
//   } catch (e) {
//     dispatch(hasError(e.message));
//     return console.error(e.message);
//   }
};

const HomePageSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    bannerSuccess: (state, action) => {
      state.bannerItems = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  startLoading,
  hasError,
  bannerSuccess,
} = HomePageSlice.actions;
export default HomePageSlice.reducer;
