import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchBanner } from "../../store/slices/HomePageSlice";

// SwiperJS
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/navigation';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

// Pricing page components
import Banner from "./Banner";

function Header() {
  const dispatch = useDispatch();
  const { bannerItems } = useSelector((state) => state.banner);
  // install SwiperJS modules
  SwiperCore.use([Autoplay, Navigation]);

  useEffect(() => {
    dispatch(fetchBanner());
  }, []);

  return (
    <Swiper
      autoplay={{ delay: 5000 }}
      speed={800}
      spaceBetween={1}
      slidesPerView={1}
      navigation
      loop
    >
      {bannerItems.map((banner, index) => (
        <SwiperSlide key={index}>
          <Banner
            image={require(`../../assets/images/cristlesteak/home/${banner.image}`)}
            label={banner.label}
            title={banner.title}
            description={banner.description}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default Header;
