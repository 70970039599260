import React from "react";
import { useSelector, useDispatch } from "react-redux";

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MuiLink from "@mui/material/Link";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

const MenuItemCard = ({ image, title, prise, description, action }) => {
  const cardActionStyles = {
    display: "flex",
    alignItems: "center",

    "& .material-icons, .material-icons-round,": {
      transform: `translateX(2px)`,
      transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
    },

    "&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round":
      {
        transform: `translateX(6px)`,
      },
  };
  const { restuarant } = useSelector((state) => state.restuarant);

  // const curruncyType = process.env.REACT_APP_API_URL;

  const style = {overflow: 'hidden',
  display: 'block',
    whiteSpace: 'nowrap', width: '200px', height: '200px'
  }
  const pStyle = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "block",
    height: "200px",
  };
  const curruncyType = "$";
  return (
    <Card>
      <MKBox position="relative" borderRadius="lg" mx={2} mt={-3}>
        <MKBox
          component="img"
          src={image}
          alt={title}
          borderRadius="lg"
          mt={5}
          shadow="md"
          width={250}
          height={200}
          position="relative"
          zIndex={1}
        />
      </MKBox>
      <MKBox p={3} mt={-2}>
        <MKTypography display="inline" variant="h4" textTransform="capitalize">
          {title}
        </MKTypography>

        {restuarant != 0 && (
          <MKBox pt={2} mt={-2}>
            <MKTypography variant="h6" textTransform="capitalize" align="left">
              {curruncyType}
              {prise}
            </MKTypography>
          </MKBox>
        )}

        <MKBox mt={1} mb={3}>
          <div>
            {" "}
            <p style={pStyle}>{description}</p>
          </div>
          {/* <MKTypography variant="body2" component="p" color="text" height={200}>
            {description}
          </MKTypography> */}
        </MKBox>
        <MKTypography
          component={Link}
          to={action.route}
          variant="body2"
          fontWeight="regular"
          color={action.color ? action.color : "dark"}
          sx={cardActionStyles}
        >
          {action.label}
          <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
        </MKTypography>
      </MKBox>
    </Card>
  );
};

// Typechecking props for the MenuItemCard
MenuItemCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  prise: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default MenuItemCard;
