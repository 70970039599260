import React from "react";
import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";

function CopyRight({ copyright }) {
  return (
    <>
      <Grid item xs={12} sx={{ textAlign: "center", my: 3 }} color="dark">
        <MKTypography
                display="block"
                variant="button"
                fontWeight="bold"
                textTransform="capitalize"
                color="dark"
                mb={1}
              >
                 {copyright} 
              </MKTypography>
      </Grid>
    </>
  );
}

export default CopyRight;