import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import appSlice from "./slices/app/appSlice";
import ResturatSlice from "./slices/ResturatSlice";
import MenuCategorySlice from "./slices/MenuCategorySlice";
import MenuItemSlice from "./slices/MenuItemSlice";
import HomePageSlice from "./slices/HomePageSlice";

const reducer = combineReducers({
  app: appSlice,
  restuarant: ResturatSlice,
  menuCategory: MenuCategorySlice,
  menuItem: MenuItemSlice,
  banner : HomePageSlice,
});

const store = configureStore({
  reducer,
})
export default store;