import React from "react";
import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import PlayStore from "assets/images/cristlesteak/footer/play_store.png";
import AppStore from "assets/images/cristlesteak/footer/app_store.png";

function AppDownload() {
  return (
    <>
        <Grid item xs={6} md={3} sx={{ mb: 3 }}>
            <MKTypography
                display="block"
                variant="button"
                fontWeight="bold"
                textTransform="capitalize"
                color="error"
                mb={1}
            >
                APP Download
            </MKTypography>

            <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                <MKBox component="li" p={0} m={0} lineHeight={1.25}>
                      <MKTypography
                        component="a"
                        href="https://test.cisworld.lk/"
                        target="_blank"
                        rel="noreferrer"
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                        color="error"
                      >
                        <MKBox component="img" src={AppStore} alt="" maxWidth="10rem" mb={2} /> 
                      </MKTypography>
                    
                </MKBox>

                <MKBox component="li" p={0} m={0} lineHeight={1.25}>
                      <MKTypography
                        component="a"
                        href="https://test.cisworld.lk/"
                        target="_blank"
                        rel="noreferrer"
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                        color="error"
                      >
                        <MKBox component="img" src={PlayStore} alt="" maxWidth="10rem" mb={2} /> 
                      </MKTypography>
                    
                </MKBox>
                
              </MKBox>
        </Grid>
    </>
  );
}

export default AppDownload;

