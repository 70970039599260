import { createSlice } from "@reduxjs/toolkit";

const initialState = { 
    brand: "Castle Steak House" ,
    homeLink : "/",
    facebook : "",
    inster : "",
    themeColor : "error"
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    
  },
});

//export const {  } = appSlice.actions
export default appSlice.reducer;