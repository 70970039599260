import React from "react";
import DefaultNavbar from "./sections/DefaultNavbar";
import routes from "routes"; // Routes
import MKBox from "components/MKBox";
// import logo from "assets/images/cristlesteak/castlesteak-logo.png"; // logo
// import { useSelector } from "react-redux";

const Navbar = () => {
  const brandName = "Castle Steak";

  return (
    <MKBox bgColor="white" py={0.25}>
    <DefaultNavbar
      routes={routes}
      action={{
        type: "external",
        route: "/",
        label: "Book now",
        color: "warning",
      }}
      transparent
      relative
    />
  </MKBox>
  );
};

export default Navbar;
