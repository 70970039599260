import React from "react";
import Navbar from "layouts/Navbar";
import Banner from "layouts/Banner";
import MKBox from "components/MKBox";

function Header() {
  return (
    <>
      <Navbar />
    </>
  );
}

export default Header;
