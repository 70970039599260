// react-router components
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import "./assets/css/styles.css";

// routes
import routes from "routes";

// themes
import theme from "assets/theme";
import Home from "pages/Home";
import Menu from "pages/Menu";
import MenuItem from "pages/MenuItem/Index";

export default function App() {
  //  const { pathname } = useLocation();

  //   // Setting page scroll to 0 when changing the route;
  //   useEffect(() => {
  //   }, [input])(() => {;
  //    document.documentElement.scrollTop = 0;
  //     document.scrollingElement.scrollTop = 0;
  //  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
     if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.id}
          />
        );
      }

      return null;
    });

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          {getRoutes(routes)}
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/menu-item" element={<MenuItem />} />
        </Routes>
      </ThemeProvider>
    </Router>
  );
}
