import Header from "layouts/Header";
import Card from "@mui/material/Card";
import Footer from "layouts/Footer";
import SelectorOption from "./sections/SelectorOption";
import MenuItems from "./sections/MenuItems";
import Navbar from "layouts/Navbar";
import Banner from "./sections/Banner";
import MKBox from "components/MKBox";

function Menu() {

  return (
    <MKBox bgColor="primary">
      <Navbar />
      {/* <Banner /> */}
      <MKBox mt={1}>
      <Card
        // sx={{
        //   p: 0,
        //   mx: { xs: 2, lg: 3 },
        //   mt: -8,
        //   mb: 4,
        //   boxShadow: ({ boxShadows: { xxl } }) => xxl,
        // }}
      >
        <SelectorOption />
        <MenuItems />
      </Card>
      </MKBox>

      <Footer />
    </MKBox>
  );
}

export default Menu;
