import React from "react";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function UsefullLinks() {
    const menus = {
              items: [
                { name: "blogs", href: "" },
                { name: "FAQ", href: "" },
                { name: "privacy policies", href: "" },
                { name: "terms & conditions", href: "" },
                { name: "support", href: "" },
              ],            
        
    }
  return (
    <>
      <Grid item xs={12} md={4} sx={{ mb: 3 }}>
              <MKTypography
                display="block"
                variant="button"
                fontWeight="bold"
                textTransform="capitalize"
                color="error"
                mb={1}
              >
                Useful Links
              </MKTypography>
              <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                {menus.items.map(({ name, href }) => (
                  <MKBox key={name} component="li" p={0} m={0} lineHeight={1.25}>
                      <MKTypography
                        component="a"
                        href={href}
                        target="_blank"
                        rel="noreferrer"
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                        color="error"
                      >
                        {name}
                      </MKTypography>
                    
                  </MKBox>
                ))}
              </MKBox>
      </Grid>
    </>
  );
}

export default UsefullLinks;