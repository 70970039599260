import { createSlice } from "@reduxjs/toolkit";
import api from "../api";

const initialState = {
  categories: [],
  isLoading: false,
  error: false,
  category: 0
};

export const fetchMenuCategories = (restuarant) => async (dispatch) => {
  dispatch(startLoading());
  try {
    let id = restuarant == 0 ? "" : `id=${restuarant}`;
    await api.get(`/get_menu-categories?${id}`).then((response) => {
      dispatch(menuCategorySuccess(response.data.data));
    });
  } catch (e) {
    dispatch(hasError(e.message));
    return console.error(e.message);
  }
};

const MenuCategorySlice = createSlice({
  name: "menuCategory",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    menuCategorySuccess: (state, action) => {
      state.categories = action.payload;
      state.isLoading = false;
    },
    selectCategory: (state, action) => {
      state.category = action.payload;
    },
  },
});

export const { startLoading, hasError, menuCategorySuccess, selectCategory } =
  MenuCategorySlice.actions;
export default MenuCategorySlice.reducer;
