// @mui material components
import Icon from "@mui/material/Icon";
import Home from "pages/Home";
import Menu from "pages/Menu";

// Pages

const routes = [
  {
    id: 1,
    name: "Home",
    icon: <Icon>dashboard</Icon>,
    route: "/",
    component: <Home />,
  },
  {
    id: 2,
    name: "Menu",
    icon: <Icon>menu</Icon>,
    route: "/menu",
    component: <Menu />,
  },
  {
    id: 3,
    name: "Restuatens",
    icon: <Icon>restaurant</Icon>,
    route: "/",
    component: <Home />,
  },
];

export default routes;
