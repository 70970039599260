import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchMenuItems } from "../../../store/slices/MenuItemSlice";

// @mui material components
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import MenuItemCard from "./MenuItemCard";

const MenuItems = () => {
  const dispatch = useDispatch();
  const { menuItems } = useSelector((state) => state.menuItem);
  const { category } = useSelector((state) => state.menuCategory);
  const { restuarant } = useSelector((state) => state.restuarant);

  
    useEffect(() => {
      dispatch(fetchMenuItems(restuarant, category));
    }, [restuarant, category]);
  
  const stringConversion = (str) => {
    return str.toLowerCase().split(" ").join("_");
  }
  
  return (
    <Container>
      <Grid container spacing={3} justifyContent="left">
        {menuItems.map((menu) => {
          
          const imageSrc = require(`assets/images/cristlesteak/menuItems/${menu.images[0].name}`);
          return (
            <Grid item xs={12} md={6} lg={4} key={menu.id}>
              <MKBox pt={6} pb={3} px={3} xs={3} lg={3}>
                <MenuItemCard
                  image={imageSrc}
                  title={menu.name}
                  prise={menu.prise}
                  description={menu.caption}
                  action={{
                    route: `/menu-item?id=${menu.id}&restuarant=${restuarant}`,
                    color: "error",
                    label: "More Details",
                  }}
                />
              </MKBox>
            </Grid>
          );
            
        })}
      </Grid>
    </Container>
  );
};

export default MenuItems;
