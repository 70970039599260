import React,{useEffect} from "react";
import MKBox from "components/MKBox";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import MKTypography from "components/MKTypography";
import LinkedIn from "@mui/icons-material/LinkedIn";
import WhatsApp from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ReactPlayer from "react-player";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchMenuItemData,
  fetchRestuarantsByMenuItem,
  selectRestuarant,
  setDisplayImage
} from "../../../store/slices/MenuItemSlice";
import FaceBookShareLink from "react-facebook-share-link";
import TwitterShareLink from "react-twitter-share-link";
import LinkdinShareLink from "react-linkedin-share-link";
import { WhatsappShareButton } from "react-share";
import SimpleImageSlider from "react-simple-image-slider";

const Slider = () => {
  const dispatch = useDispatch();
  const pageLink = window.location.href;
  const { menuItemData, dispalyImage } = useSelector((state) => state.menuItem);

  const buttonStyle = {
    color: "#F44335",
  };
  const socials = {
    links: [
      {
        icon: <FacebookIcon />,
        link: (
          <FaceBookShareLink link={pageLink}>
            {(link) => (
              <a href={link} target="_blank" style={buttonStyle}>
                <FacebookIcon />
              </a>
            )}
          </FaceBookShareLink>
        ),
      },
      {
        icon: <TwitterIcon />,
        link: (
          <TwitterShareLink link={pageLink}>
            {(link) => (
              <a href={link} target="_blank" style={buttonStyle}>
                <TwitterIcon />
              </a>
            )}
          </TwitterShareLink>
        ),
      },
      {
        icon: <LinkedIn />,
        link: (
          <LinkdinShareLink link={pageLink}>
            {(link) => (
              <a href={link} target="_blank" style={buttonStyle}>
                <LinkedIn />
              </a>
            )}
          </LinkdinShareLink>
        ),
      },
      {
        icon: <WhatsApp />,
        link: (
          <WhatsappShareButton url={pageLink}>
            <WhatsApp />
          </WhatsappShareButton>
        ),
      },
    ],
  };
  const stringConversion = (str) => {
    return str.toLowerCase().split(" ").join("_");
  };

  const imagesLoad = () => {
    
    let imagesArray = dispalyImage.map((image) =>({ 
      url: require(`assets/images/cristlesteak/menuItems/${image.url}`),
  }));
    return (imagesArray);
    
  };

  return (
    <>
      <MKBox component="section" mt={3} mb={3}>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6} mb={1} height={400}>
              {menuItemData.length !== 0 && (
                <SimpleImageSlider
                  width={534}
                  height={400}
                  images={dispalyImage}
                  showBullets={true}
                  showNavs={true}
                />
              )}
            </Grid>

            <Grid item xs={12} lg={6} height={400}>
              <MKTypography
                variant="body2"
                component="p"
                color="text"
                height={200}
              >
                {menuItemData.caption}
              </MKTypography>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <Grid container spacing={3}>
                <Grid item xs={6} lg={1}></Grid>
                {menuItemData.length !== 0 &&
                  menuItemData.images.map((image, key) => {
                    let imageSrc = require(`assets/images/cristlesteak/menuItems/${image.name}`);
                    return (
                      <Grid item xs={6} lg={2} key={key}>
                        <button
                          type="button"
                          data-image={imageSrc}
                          value={image.name}
                        >
                          <MKBox
                            component="img"
                            src={imageSrc}
                            alt={menuItemData.name}
                            borderRadius="sm"
                            width={80}
                            height={80}
                            position="relative"
                            zIndex={1}
                          />
                        </button>
                      </Grid>
                    );
                  })}
                <Grid item xs={6} lg={1}></Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Grid container spacing={3}>
                {socials.links.map(({ icon, link }, key) => (
                  <Grid item xs={12} lg={2} key={key}>
                    <MKTypography
                      component="p"
                      variant="h1"
                      color="error"
                      opacity={0.8}
                      mr={key === socials.length - 1 ? 0 : 2.5}
                    >
                      {link}
                    </MKTypography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} lg={9} textAlign="justify">
              <MKTypography variant="button" color="text">
                {menuItemData.description}
              </MKTypography>
            </Grid>
            <Grid item xs={12} lg={2}>
              <ReactPlayer url={menuItemData.video} width={300} height={200} />
            </Grid>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
};

export default Slider;