import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchRestuarants, selectRestuarant } from "../../../store/slices/ResturatSlice";
import {
  fetchMenuCategories,
  selectCategory,
} from "../../../store/slices/MenuCategorySlice";


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";

const SelectorOption = () => {
  const dispatch = useDispatch();
  const { restuarants, restuarant } = useSelector((state) => state.restuarant);
  const {categories, category} = useSelector((state) => state.menuCategory);
  const handleRestuarantChange = (event) => {
    dispatch(selectRestuarant(event.target.value));
  };
  const handleCategoryChange = (event) => {
    dispatch(selectCategory(event.target.value));

  };

  const selectStyles = {
    padding: "1px",
    margin: "2px",
    background: "#fff",
    borderColor: "##344767",
    color: "##344767",
    fontSize: "1rem",
  };

   useEffect(() => {
    dispatch(fetchRestuarants());
     dispatch(fetchMenuCategories(restuarant));
  }, [restuarant]);
  
  return (
    <MKBox component="section">
      <Container>
        <Grid container spacing={3} mt={7} ml={5} mr={5} mb={2}>
          <Grid item xs={12} lg={5} textAlign="center">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} textAlign="right">
                <MKTypography variant="h5" mb={1} mr={2}>
                  Select Restuarant :{" "}
                </MKTypography>
              </Grid>
              <Grid item xs={12} sm={6} textAlign="left">
                <select
                  value={restuarant}
                  onChange={handleRestuarantChange}
                  style={selectStyles}
                >
                  <option value="0" key={"all"}>
                    All
                  </option>
                  {restuarants.map((option) => (
                    <option value={option.id} key={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={7} textAlign="center">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} textAlign="right">
                <MKTypography variant="h5" mb={1} mr={2}>
                  Select Menu :{" "}
                </MKTypography>
              </Grid>
              <Grid item xs={12} sm={6} textAlign="left">
                <select
                  value={category}
                  onChange={handleCategoryChange}
                  style={selectStyles}
                >
                  <option value="0" key={"all"}>
                    All
                  </option>
                  {categories.map((option) => (
                    <option value={option.id} key={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );

};


export default SelectorOption;