import { createSlice } from "@reduxjs/toolkit";
import React, { useEffect } from "react";
import api from "../api";

const initialState = {
  menuItems: [],
  isLoading: false,
  error: false,
  menuItemData: [],
  restuarantsByMenuItem: [],
  restuarant: 0,
  menuItemPrise: 0,
  dispalyImage: []
};

export const fetchMenuItems = (restuarant,category) => async (dispatch) => {
  dispatch(startLoading());
  try {
    let restuarant_id = restuarant == 0 ? "" : `restuarant=${restuarant}`;
    let category_id = category == 0 ? "" : `&category=${category}`;
    await api
      .get(`/get_menu-items?${restuarant_id}${category_id}`)
      .then((response) => {
        dispatch(menuItemSuccess(response.data.data));
      });
  } catch (e) {
    dispatch(hasError(e.message));
    return console.error(e.message);
  }
};

export const fetchMenuItemData = (id, restuarant) => async (dispatch) => {
  dispatch(startLoading());
  try {
    let restuarant_id =
      (restuarant == 0 || restuarant
        == null )? "" : `&restuarant=${restuarant}`;
    await api.get(`/get_menu-item?id=${id}${restuarant_id}`).then((response) => {
      dispatch(menuItemDataSuccess(response.data.data[0]));
      dispatch(setMenuItemPrise(response.data.data[0].prise));
      dispatch(setDisplayImage(response.data.data[0]));
        
    });
  } catch (e) {
    dispatch(hasError(e.message));
    return console.error(e.message);
  }
};

export const fetchRestuarantsByMenuItem = (id) => async (dispatch) => {
  dispatch(startLoading());
  try {
    await api.get(`/get_branches_by_menu_item?menu_item=${id}`).then((response) => {
      dispatch(restuarantByMenuItemSuccess(response.data.data));
      
    });
  } catch (e) {
    dispatch(hasError(e.message));
    return console.error(e.message);
  }
};

const MenuItemSlice = createSlice({
  name: "menuItem",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    menuItemSuccess: (state, action) => {
      state.menuItems = action.payload;
      state.isLoading = false;
    },
    menuItemDataSuccess: (state, action) => {
      state.menuItemData = action.payload;
      state.isLoading = false;
    },
    restuarantByMenuItemSuccess: (state, action) => {
      state.restuarantsByMenuItem = action.payload;
      state.isLoading = false;
    },
    selectRestuarant: (state, action) => {
      state.restuarant = action.payload === null ? 0 : action.payload;
    },
    setMenuItemPrise: (state, action) => {
      state.menuItemPrise = action.payload === null ? 0 : action.payload;
      
    },
   
    setDisplayImage: (state, action) => {
      if (action.payload.images !== undefined) {
        state.dispalyImage = action.payload.images.map((image) => ({
          url: require(`/src/assets/images/cristlesteak/menuItems/${image.name}`),
        }));
        
      }
        
 
    },
  },
});

export const {
  startLoading,
  hasError,
  menuItemSuccess,
  menuItemDataSuccess,
  restuarantByMenuItemSuccess,
  selectRestuarant,
  setMenuItemPrise,
  setDisplayImage,
} = MenuItemSlice.actions;
export default MenuItemSlice.reducer;
