import Header from "./Header";
import Card from "@mui/material/Card";
import Footer from "layouts/Footer";
import MKBox from "components/MKBox";
import Navbar from "layouts/Navbar";
import bgImage from "assets/images/cristlesteak/home/bg-color.jpg";
import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";
import Category from "./Category";

function Home() {

  return (
    <>
      <Navbar />
      <Header /> 
      <MKBox m={2}>
      <MKBox m={2}>
      <Grid container spacing={2} justifyContent="center"
            alignItems="center">
        <Category />
      </Grid>
      </MKBox>
      <MKBox
        minHeight="85vh"
        width="100%"
        height="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.2),
              rgba(gradients.dark.state, 0.2)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
          borderRadius: 2
        }}
      >
        <MKBox ml={3}>
          <MKTypography>
              {/* {} */}
          </MKTypography>
          <Card
              sx={{
                p: 2,
                mx: { xs: 2, lg: 3 },
                mt: -8,
                mb: 4,
                boxShadow: ({ boxShadows: { xxl } }) => xxl,
              }}
            >
              
          </Card>
        </MKBox>
        
        </MKBox>

        
      </MKBox>
      
      <MKBox pt={6} px={1} mt={6}>
        <Footer />
      </MKBox>
    </>




  );
}

export default Home;
