import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MKTypography from "components/MKTypography";
import Grid from "@mui/material/Grid";

// Pricing page components
import Banner from "./Banner";

function Category() {
  const dispatch = useDispatch();
  const { categoryTime } = useSelector((state) => state.banner);

  return (
    <>
     {categoryTime.map((Category, index) => (
        <Grid item xs={12} lg={2} key = {index}>
        <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ px: { xs: 1, lg: 0 } }}
          >
            <MKTypography variant="h4" color="primary">
               {(Category.name.toUpperCase())}
            </MKTypography>
            <MKTypography variant="body1" color="primary" opacity={0.8}>
              {(Category.time.toUpperCase())}
          </MKTypography>
          </Grid>
      </Grid>
      ))}
    </>
  );
}

export default Category;
