import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "layouts/Header";
import Card from "@mui/material/Card";
import Footer from "layouts/Footer";
import SelectorOption from "./sections/SelectorOption";
import Navbar from "layouts/Navbar";
import Banner from "./sections/Banner";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Slider from "./sections/Slider";
import {fetchMenuItemData, fetchRestuarantsByMenuItem, selectRestuarant, setDisplayImage } from "../../store/slices/MenuItemSlice";

const MenuItem = () => {
  const dispatch = useDispatch();
  const { menuItemData, restuarant, menuItemPrise } =
    useSelector((state) => state.menuItem);
  
  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get("id");
  const requestRestuarant = queryParameters.get("restuarant");
  const curruncyType = "$";
  useEffect(() => {
    dispatch(fetchMenuItemData(id, requestRestuarant));
    dispatch(fetchRestuarantsByMenuItem(id));
    dispatch(selectRestuarant(requestRestuarant));
    dispatch(setDisplayImage(menuItemData));
  }, []);
  useEffect(() => {
    dispatch(fetchMenuItemData(id, restuarant));
  }, [restuarant]);
  return (
    <>
      <Navbar />
      <Banner />

      <Card
        sx={{
          p: 0,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <SelectorOption />
        <MKBox component="section" mt={2}>
          <Container>
            <Grid item xs={12} lg={6} m={0} p={0} textAlign={"left"}>
              <MKTypography variant="h5" mb={1} mr={2}>
                {menuItemData.name}{" "}
              </MKTypography>
            </Grid>
            {menuItemPrise != 0 && (
              <Grid item xs={12} lg={6}>
                <MKTypography variant="h5" mb={1} mr={2}>
                  {curruncyType}
                  {menuItemPrise}
                </MKTypography>
              </Grid>
            )}
          </Container>
        </MKBox>

        <MKBox component="section" mt={5}>
          <Container>
            <Grid container spacing={3}>
              <Slider />
            </Grid>
          </Container>
        </MKBox>
      </Card>

      <Footer />
    </>
  );
};

export default MenuItem;
